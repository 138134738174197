.__user_form_dialog {
  font-size: 12px;

  p {
    margin-bottom: 16px;
  }

  table {
    border-collapse: collapse;
    border: 1px solid #ccc;
    th {
      font-size: 14px;
      padding: 8px 20px;
    }
    td {
      padding: 20px;
      vertical-align: top;
    }
    th, td {
      border: 1px solid #ccc;
    }
  }
}